<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="地址">
          <el-input v-model="address" placeholder="请输入地址" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus" v-if="isAdmin">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="drillList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="startTime" label="演练时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.startTime|dateFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="演练地点" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="departmentName" label="组织部门" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="head" label="演练负责人" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column prop="content" label="演练内容" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="plan" label="演练方案" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="conditions" label="演练情况" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="conclusion" label="演练小结" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="improveIdea" label="改进意见" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column prop="attendPerson" label="参加人员" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="参加人员" placement="top">
              <el-button type="success" @click="open('user',scope.row)" size="mini" icon="el-icon-user" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="isAdmin">
              <el-button type="danger" @click="delDrill(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看附件" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" drill="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加合同':'修改合同'" :visible.sync="drillShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="drill" ref="drillForm" label-width="140px">
        <el-form-item label="演练时间" prop="startTime">
          <el-date-picker v-model="drill.startTime" type="date" placeholder="选择演练时间" size="small">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="组织部门">
          <el-input v-model="drill.department" placeholder="请输入组织部门" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="演练地址" prop="address">
          <el-input v-model="drill.address" placeholder="请输入演练地址" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="演练负责人" prop="head">
          <el-input v-model="drill.head" placeholder="请输入演练负责人" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="演练内容" prop="content">
          <el-input v-model="drill.content" placeholder="请输入演练内容" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="演练方案" prop="plan">
          <el-input v-model="drill.plan" placeholder="请输入演练方案" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="演练情况" prop="conditions">
          <el-input v-model="drill.conditions" placeholder="请输入演练情况" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="演练小结" prop="conclusion">
          <el-input v-model="drill.conclusion" placeholder="请输入演练小结" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="改进意见" prop="improveIdea">
          <el-input v-model="drill.improveIdea" placeholder="请输入改进意见" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="记录人签名" prop="recordPerson">
          <el-input v-model="drill.recordPerson" placeholder="请输入记录人签名" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="消防安全管理人签名" prop="fireManagePerson">
          <el-input v-model="drill.fireManagePerson" placeholder="请输入消防安全管理人签名" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload class="upload-demo" ref="upload" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :on-success="uploadSuccess" :on-error="uploadError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="drillShow = false">取 消</el-button>
        <el-button type="primary" @click="addDrill" v-show="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateDrill" v-show="!optFlag">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog title="附件" :visible.sync="enclosureShow" width="30%">
      <el-image :src="enclosure" fit="fill" :lazy="true" :preview-src-list="[enclosure]"></el-image>
    </el-dialog>
    <!-- 参与人员 -->
    <!-- <el-dialog title="添加人员" :visible.sync="addShow" width="40%" v-dialogDrag :close-on-click-modal="false" @open="initTable2">
      <div class="query">
        <el-cascader :options="departmentList" v-model="departmentIds" clearable :show-all-levels="false" @change="chooseDepartment" :props="{value:'id',checkStrictly:true}" size="small">
        </el-cascader>
        <el-select v-model="unitRoleId" placeholder="请选择类型" size="small" clearable @change="chooseRole">
          <el-option v-for="(item,i) in roleList" :key="i" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-table :data="memberList2" border stripe @selection-change="handleSelectionChange" row-key="id" ref="multipleTable">
        <el-table-column type="selection" width="55" reserve-selection>
        </el-table-column>
        <el-table-column label="姓名" prop="realname">
        </el-table-column>
        <el-table-column label="部门" prop="departmentName">
        </el-table-column>
        <el-table-column label="职位" prop="unitRoleName">
        </el-table-column>
        <el-table-column label="电话" prop="phoneNumber">
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="addShow2 = false">取 消</el-button>
        <el-button type="primary" @click="addDrillMember">添 加</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      address: '',
      token: '',
      drillList: [],
      departmentList: [],
      drillId: '',
      departmentIds: [],
      departmentId: '',
      userListIds: [],
      drill: {
        code: '',
        companyName: '',
        endTime: '',
        level: '',
        personName: '',
        protectLocation: '',
        startTime: '',
        vaildTime: ''
      },
      drillCopy: {},
      enclosure: '',
      drillShow: false,
      enclosureShow: false,
      addShow: false,
      optFlag: true,
      loading: false,
      rules: {
        companyName: [
          { required: true, message: '请输入维护保养企业名称', trigger: 'blur' }
        ],
        personName: [
          { required: true, message: '请输入维保企业负责人', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '请选择维护保养资质等级', trigger: 'change' }
        ],
        startTime: [
          { required: true, message: '请选择合同签订日期', trigger: 'change' }
        ],
        vaildTime: [
          { required: true, message: '请选择合同起效日期', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择合同结束日期', trigger: 'change' }
        ],
        protectLocation: [
          { required: true, message: '请输入维护部位', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.initTable2()
    this.getDepartment()
    this.drillCopy = JSON.stringify(this.drill)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.address) {
        data.address = this.address
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/rescuePracticeRecord/list', { params: data })
      if (result.code === 200) {
        this.drillList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    async initTable2 () {
      const { data: result } = await this.$axios.get('/user/list')
      if (result.code === 200) {
        this.memberList = result.data.result
      }
    },
    async addDrill () {
      this.$refs.drillForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/rescuePracticeRecord/add', this.drill)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.drillShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async addDrillMember () {
      const { data: result } = await this.$axios.post('/rescuePracticeRecord/add', this.userListIds.join(','))
      if (result.code === 200) {
        this.$message.success('添加成功')
        this.addShow = false
      } else {
        this.$message.error(result.msg)
      }
    },
    async updateDrill () {
      this.$refs.drillForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/rescuePracticeRecord/edit/${this.drillId}`, this.drill)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.drillShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delDrill (id) {
      const confirm = await this.$confirm('此操作将永久删除该布局, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/rescuePracticeRecord/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.drillShow = true
          this.$nextTick(() => {
            this.$refs.drillForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'edit':
          this.optFlag = false
          this.drill = JSON.parse(JSON.stringify(row))
          this.drillShow = true
          this.drillId = row.id
          this.$nextTick(() => {
            this.$refs.drillForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'check':
          if (!row.imageUrl) {
            this.$message('该条数据暂无附件')
          } else {
            this.enclosure = row.imageUrl
            this.enclosureShow = true
          }
          break
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.drill.imageUrl = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    // 人员列表多选
    handleSelectionChange (val) {
      this.userListIds = []
      val.forEach((item, i) => {
        this.userListIds.push(item.id)
      })
    },
    async getDepartment () {
      const { data: result } = await this.$axios.post('/department/treeDepartment')
      if (result.code === 200) {
        this.departmentList = result.data
      }
    },
    // 选择部门查询
    chooseDepartment (val) {
      this.departmentId = val[val.length - 1]
      this.initTable2()
    },
    clear () {
      this.drill = JSON.parse(this.drillCopy)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-shadow: none !important;
}
.container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border: none !important;
}
</style>
